import React from "react";
import { withTranslation } from "react-i18next";
import Typewriter from 'typewriter-effect';

/* Components included */
import SplitSeparator from "../components/SplitSeparator";
import Footer from "../components/Footer";
import MarketingSlider from "../components/MarketingSlider";
import ZigZagContent from "../components/ZigZagContent";
import WhiteLabel from "../components/WhiteLabel";
import Accordions from "../components/Accordions";
import FloatingButtons from "../components/FloatingButtons";
import WinWinBlock from "../components/WinWinBlock";

/* This is just to save those blocks where we show benefits */
//import BenefitsBlock from "../components/BenefitsBlock";

/* Modal */
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

/* Clients  */
import logo from "../res/images/gimme_logo_purple.svg";
import whyOpener from "../res/images/home/why_opener.webp";

import adminScreenshot1 from "../res/images/home/qr-menu.webp";
import adminScreenshot2 from "../res/images/home/tablet-menu.webp";

//objekti for the win
const someInfo = [
  {
    headline: "zigzag-headline-1",
    img: adminScreenshot1,
    description: "zigzag-description-1",
    textFirst: true, // ako dolazi tekst prije slike
  },
  {
    headline: "zigzag-headline-2",
    img: adminScreenshot2,
    description: "zigzag-description-2",
  }
//  {
//    headline: "pharmacy",
//    img: adminScreenshot2,
//    description: "yes",
//    textFirst: true,
//  },
//  { headline: "bar", img: adminScreenshot1, description: "no" },
//  {
//    headline: "conference",
//    img: adminScreenshot2,
//    description: "maybe",
//    textFirst: true,
//  },
//  { headline: "event", img: adminScreenshot1, description: "i dont" },
//  {
//    headline: "business",
//    img: adminScreenshot2,
//    description: "know",
//    textFirst: true,
//  },
];

class Why extends React.PureComponent {
  constructor() {
    super();
    this.state = { open: false };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  /* random text */

  /* random text*/

  render() {
    const { t } = this.props;

    /* Modal */
    const { open } = this.state;
    /* Modal */
    return (
      <div className="why-wrapper">
        <div className="why-opener">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-align">
                <div className="text-helper">
                  <h1>
                    {t("why-headline")}
                    <Typewriter
                     options={{
                      autoStart: true,
                      loop: true,
                    }}
                    onInit={(typewriter) => {
                      typewriter.typeString('Restaurant')
                      .pauseFor(500)
                      .deleteChars(11)
                      typewriter.typeString( 'Night Club')
                      .pauseFor(500)
                      .deleteChars(11)
                      typewriter.typeString('Coffe Shop')
                      .pauseFor(500)
                      .deleteChars(11)
                      typewriter.typeString('Food Truck')
                      .pauseFor(500)
                      .deleteChars(11)
                      typewriter.typeString('Bakery')
                      .pauseFor(500)
                      .deleteChars(8)
                      typewriter.typeString('Ice Cream Shop')
                      .pauseFor(500)
                      .deleteChars(15)
                      typewriter.typeString('Medical ordination')
                      .pauseFor(500)
                      .deleteChars(18)
                      typewriter.typeString('Retail Shop')
                      .pauseFor(500)
                      .deleteChars(11)
                      .start();
                    }}
                  />
                  </h1>
                  <p>{t("why-subline")}</p>
                </div>
                <ul className="button-group">
                  <li>
                    <a href="https://app.joingimme.com/sign-up" className="primary-button">
                      {t("get-started")}
                    </a>
                  </li>
                  <li>
                    <span
                      onClick={this.onOpenModal}
                      className="secondary-button video"
                    >
                      {t("how-it-works")}
                    </span>
                  </li>
                </ul>
                <img
                  src={whyOpener}
                  alt="Gimme - Order food and drinks via app"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="promo-space">
            <span>{t("why-trusted")}</span>
            <ul className="no-style">
              <li>
                <img src={logo} alt="Gimme - Order food and drinks via app" />
              </li>
              <li>
                <img src={logo} alt="Gimme - Order food and drinks via app" />
              </li>
              <li>
                <img src={logo} alt="Gimme - Order food and drinks via app" />
              </li>
              <li>
                <img src={logo} alt="Gimme - Order food and drinks via app" />
              </li>
            </ul>
          </div>
        </div>

        <div className="content-wrapper">
         
          <MarketingSlider />
          <SplitSeparator />
          <ZigZagContent zigZagContent={someInfo} />
          <WinWinBlock />
          <Accordions />
          <FloatingButtons />
          <WhiteLabel />
          <Footer />
          <Modal open={open} onClose={this.onCloseModal} center>
          <iframe title="iFrame Video"  src="https://player.vimeo.com/video/371095151" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>
          </Modal>
        </div>
      </div>
    );
  }
}
export default withTranslation("page-why")(Why);

// Block with listed features
// import FeaturesIncluded from "../components/FeaturesIncluded";
// <FeaturesIncluded />