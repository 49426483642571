import React from "react";
import { Route } from "react-router-dom";

//import { withTranslation } from 'react-i18next';

/* Sub navigation */
import BackOffice from "../components/business/BackOffice";
import YourStore from "../components/business/YourStore";

/* Modal */
import "react-responsive-modal/styles.css";


class ForBusiness extends React.Component {
  /* Modal */
  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  /* Show/Hide Navigation */
  state = {
    showNavigation: false,
  };

  showNavigation = () => {
    // get how many px we've scrolled
    const top = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({
      showNavigation: top <= 100,
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.showNavigation);
    this.showNavigation();
  }
  /* Show/Hide Navigation */

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showNavigation);
  }

  /* Show/Hide Navigation */

  render() {
    //    const { t } = this.props;
    //    const { t } = useTranslation('page');

    /* Modal */
    const { open } = this.state;
    /* Modal */

    return (
      <div className="business-wrapper">
        <Route path="/business" exact component={BackOffice} />
        <Route path="/business/your-store" component={YourStore} />
      </div>
    );
  }
}

export default ForBusiness;
