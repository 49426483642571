import React, { Suspense } from 'react';
import Loader from '../Loader';

function YourStore() {
  return (
    <>
    <Suspense fallback={<Loader />}>
      <h2>Store</h2>
      <h3>123213123</h3>
      </Suspense>
    </>
  );
}

export default YourStore;
