import React from "react";
import { withTranslation } from "react-i18next";

import fitness from "../res/images/industry_fitness.png";
import hair from "../res/images/industry-hair2.webp";
import other from "../res/images/industry-other.webp";
import restaurant from "../res/images/industry-restaurant.webp";
import retail from "../res/images/industry-retail-2.webp";
import medicine from "../res/images/industry-medicine.webp";

class Industry extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="container-fluid industry-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h2>{t("industry-headline")}</h2>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <p>{t("industry-description")}</p>
            </div>
          </div>
          <div className="row industry-type">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <img src={restaurant} alt="Restaurant" />

              <h3>{t("headline-type1")}</h3>
              <p>{t("description-type1")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
              <img src={retail} alt="Retail" />
              <h3>{t("headline-type2")}</h3>
              <p>{t("description-type2")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <img src={hair} alt="Hairdresser" />
              <h3>{t("headline-type3")}</h3>
              <p>{t("description-type3")}</p>
            </div>
          </div>

          <div className="row industry-type">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <img src={medicine} alt="Pharmacy" />

              <h3>{t("headline-type4")}</h3>
              <p>{t("description-type4")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 ">
              <img src={fitness} alt="Fitness" />
              <h3>{t("headline-type5")}</h3>
              <p>{t("description-type5")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <img src={other} alt="Other" />
              <h3>{t("headline-type6")}</h3>
              <p>{t("description-type6")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("component-industry")(Industry);
