import React from "react";

class Blog extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="blog-wrapper generic-wrapper">
        <div className="meta-wrap">
          <h1>test</h1>
          <div className="content">
            <div className="intro">
              <h3>Test</h3>
              <p>test</p>
            </div>

            <div className="section">
              <h3>Test</h3>
              <p>test</p>
            </div>

            <div className="section list">
              <ul>
                <li>
                  <h5>Test</h5>
                  <p>Test21</p>
                </li>
              </ul>

              <ul>
                <li>
                  <h5>Test</h5>
                </li>
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
                <li>Email</li>
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
              </ul>

              <ul>
                <li>
                  <h5>Test</h5>
                </li>
                <li>Test</li>
              </ul>

              <ul>
                <li>
                  <h5>Test</h5>
                </li>
                <li>Test</li>
              </ul>
            </div>

            <div className="section list">
              <p>Test</p>
              <ul>
                <li>Email</li>
                <li>IP</li>
              </ul>
              <ul>
                <li>Google</li>
                <li>Stripe</li>
              </ul>

              <ul>
                <li>Test</li>
              </ul>

              <ul>
                <li>Test</li>
              </ul>
            </div>
            <div className="section">
              <h3>Test</h3>
              <p>Test</p>
              <ul className="bullepoints">
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
              </ul>
            </div>

            <div className="section">
              <p>Test</p>
              <ul className="bullepoints not">
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
                <li>Test</li>
              </ul>
            </div>

            <div className="section">
              <h3>Test</h3>
              <p>Test</p>

              <h3>Test</h3>
              <p>Test</p>

              <h3>Test</h3>
              <p>Test</p>
            </div>

            <div className="section list">
              <ul>
                <li>
                  <h5>Test</h5>
                </li>
                <li>Test</li>
              </ul>

              <ul>
                <li>
                  <h5>test</h5>
                </li>
                <li>test</li>
              </ul>

              <ul>
                <li>
                  <h5>test</h5>
                </li>
                <li>test</li>
                <li>teest</li>
                <li>test</li>
                <li>test21</li>
                <li>teest</li>
                <li>test</li>
                <li>test21</li>
              </ul>

              <ul>
                <li>
                  <h5>test</h5>
                </li>
                <li>Google.</li>
              </ul>
            </div>

            <div className="section">
              <h3>test</h3>
              <p>test</p>
              <h3>Google</h3>
              <p>
                test
                <a href="https://policies.google.com/privacy?hl=en">test</a>
              </p>

              <h3>Stripe</h3>
              <p>Google</p>

              <h3>test</h3>
              <p>test</p>

              <h3>test</h3>
              <p>test</p>
              <ul>
                <li>Niderämterstrasse 72</li>
                <li>4657 Dulliken</li>
                <li>🇨🇭Switzerland</li>
                <li>legal@zirelco.ch</li>
                <li> 01 Januar 2020</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
