import React from "react";
import { useTranslation } from "react-i18next";
import LangSwitcher from "./LangSwitcher";

function Footer() {
  const { t } = useTranslation("footer");

  return (
    <div className="container-fluid">
      <div className="footer-component">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <h3>Gimme App</h3>
            <p>{t("made-by")} <span role="img" aria-label="Description of the overall image"> &#128155;</span> in Zürich & Vienna</p>
            <span>
              <a href="http://zirelco.ch">Powered by: Zirelco.ch</a>
            </span>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h4>{t("footer-connect")}</h4>
            <ul className="no-style">
              <li>
                <a href="http://zirelco.ch/expert.html">{t("footer-contact")}</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/gimme-payments/">Linkedin</a>
              </li>
              <li>
                {" "}
                <a href="https://twitter.com/GimmePayments">Twitter</a>
              </li>
              <li>
                {" "}
                <a href="https://www.instagram.com/joingimme/">Instagram</a>
              </li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <h4>{t("our-services")}</h4>
            <ul className="no-style">
              <li><a href="mailto:goran.ramljak@zirelco.ch">{t("white-label")}</a></li>
              <li><a href="mailto:goran.ramljak@zirelco.ch">{t("for-business")}</a></li>
              <li><a href="mailto:goran.ramljak@zirelco.ch">{t("for-users")}</a></li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-md-offset-1 switcher">
            <p>{t("footer-languages")}</p>
            <LangSwitcher />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

//export default(Footer);
