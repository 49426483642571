import React from "react";
import { withTranslation } from "react-i18next";

class AboutUsPopup extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="about-popup">
        <div className="greetings">
          <span role="img" aria-label="Description of the overall image">{t("about-subline")} &#128075;</span>
          <h3>{t("about-headline")}</h3>
        </div>
        <div className="details">
          <ul className="list-helper">
         
            <li className="chat">
              <span>Live Chat</span>
              <span>{t("about-response")}</span>
            </li>
            <li className="email">
              <span>goran.ramljak@zirelco.ch</span>
              <span>{t("about-email")}</span>
            </li>
          </ul>
        </div>
        <div className="address">
          <span>{t("about-parental")}</span>
          <span>
            Zirelco AG, Niderämterstrasse 72, 4657 Dulliken, Switzerland
          </span>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-about")(AboutUsPopup);
/*

   <li className="phone">
              <span>0043 664 118 42 17</span>
              <span>{t("about-time")}</span>
            </li>

*/