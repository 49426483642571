import React from "react";
import { withTranslation } from "react-i18next";

class SecureSeparator extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="container-fluid secure-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h2>{t("secure-headline")}</h2>
              <span className="secondary-button">{t("secure-contact")}</span>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ul className="vertical-list">
                <li>{t("secure-fact1")}</li>
                <li>{t("secure-fact2")}</li>
                <li>{t("secure-fact3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-secure")(SecureSeparator);

//export default(SecureSeparator);
