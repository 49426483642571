import React from "react";
import { withTranslation } from "react-i18next";

import adminScreenshot1 from "../res/images/for-business/admin-1.webp";
import adminScreenshot2 from "../res/images/for-business/admin-2.webp";
import adminScreenshot3 from "../res/images/for-business/admin-3.webp";
import adminScreenshot4 from "../res/images/for-business/loyalty-system.webp";
import adminScreenshot5 from "../res/images/for-business/themes.webp";
import adminScreenshot6 from "../res/images/for-business/qr-codes.webp";

//TODO: update calcCardStats to calculate trees(if possible) & money saved
class StickyScroll extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid sticky-component">
        <div className="background-helper">
          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <h1 className="center-align">{t("sticky-opener")}</h1>
              <p className="center-align opener">{t("sticky-paragraph")}</p>
            </div>
          </div>

          <div className="row sticky-content first">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2>{t("sticky-headline1")}</h2>
              <p>{t("sticky-description1")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot1} alt="Industry" />
            </div>
          </div>

          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2>{t("sticky-headline2")}</h2>
              <p>{t("sticky-description2")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot2} alt="Industry" />
            </div>
          </div>

          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2>{t("sticky-headline3")}</h2>
              <p>{t("sticky-description3")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot4} alt="Industry" />
            </div>
          </div>


          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2>{t("sticky-headline4")}</h2>
              <p>{t("sticky-description4")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot3} alt="Industry" />
            </div>
          </div>

          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2>{t("sticky-headline5")}</h2>
              <p>{t("sticky-description5")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot6} alt="Industry" />
            </div>
          </div>

          <div className="row sticky-content">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
              <h2 className="in-development-badge">{t("sticky-headline6")}</h2>
              <p>{t("sticky-description6")}</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <img src={adminScreenshot5} alt="Industry" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("component-stickyscroll")(StickyScroll);

/* Add to headline
className="in-development-badge"
*/