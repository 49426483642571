import React from "react";
import { withTranslation } from "react-i18next";

class SplitSeparator extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="container-fluid split-separator">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h2>{t("separator-headline")}</h2>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <p>{t("separator-description")}</p>
            </div>
          </div>
          <div className="row partners">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <p>{t("pos-providers")}</p>
              <span className="secondary-button">{t("pos-contact")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-split-separator")(SplitSeparator);

// export default(SplitSeparator);
