import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './res/images/gimme_logo_purple.svg';

function NavBar({ togglePopup }) {
  const { t } = useTranslation('navbar');

  const [stickyClass, setStickyClass] = useState('relative');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 72 ? setStickyClass('sticky-on-top') : setStickyClass('relative');
    }
  };



  const openGimmeClient = () => {
    window.open('https://app.joingimme.com/sign-up', '_self');
  }

  return (
    <div className={`navigation ${stickyClass}`}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <span>
              <NavLink to='/'>
                <img src={logo} alt='Gimme - Order food and drinks via app' />
              </NavLink>
            </span>
            <div className='float-right'>
              <input type='checkbox' id='button' />
              <label htmlFor='button'></label>
              <ul>
                <li>
                  <NavLink
                    to='/'
                    exact
                    activeStyle={{ color: '#38454c' }}
                    style={{ textDecoration: 'inherit', color: '#7f91a8' }}
                  >
                    {t('Why?')}
                  </NavLink>
                </li>
                <li>{createNavLink(t('For businesses'), '/business')}</li>
                <li>{createNavLink(t('For users'), '/users')}</li>
                <li
                  onClick={togglePopup}
                  style={{ cursor: 'pointer' }}
                >
                  {t('About us')}
                </li>
                <li>{createNavLink(t('Pricing'), '/pricing')}</li>
                
              </ul>
              <span className='primary-button' onClick={openGimmeClient}>
              {t('getStartedCTA')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function createNavLink(linkName, route, exact) {
  return (
    <NavLink
      activeStyle={{ color: '#38454c' }}
      style={{ textDecoration: 'inherit', color: '#7f91a8' }}
      to={route}
    >
      {linkName}
    </NavLink>
  );
}

export default NavBar;

/*
blog backup

<li>{createNavLink(t('Blog'), '/blog')}</li>

*/