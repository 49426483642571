import React from "react";
import { withTranslation } from "react-i18next";

import StickyScroll from "../components/StickyScroll";
import Gdpr from "../components/Gdpr";
import Accordions from "../components/Accordions-third";
import Review from "../components/Review";
import HowItWorksOpener from "../components/HowItWorksOpener";
import FeatureBoxList from "../components/FeatureBoxList";
import FloatingButtons from "../components/FloatingButtons";
import Footer from "../components/Footer";


import logo_white from "../res/images/for-users/gimme-logo-white.svg";



//TODO: update calcCardStats to calculate trees(if possible) & money saved
class ForUsers extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="users-wrapper">
      <HowItWorksOpener />
        <div className="container-fluid">
          <div className="promo-space">
            <div className="steps">
              <div className="step">
                <h3>{t("how-it-works-headline1")}</h3>
                <p>{t("how-it-works-description1")}</p>
              </div>
              <div className="step">
                <h3>{t("how-it-works-headline2")}</h3>
                <p>{t("how-it-works-description2")}</p>
              </div>
              <div className="step">
                <h3>{t("how-it-works-headline3")}</h3>
                <p>{t("how-it-works-description3")}</p>
              </div>
            </div>
            <div className="clients">
             
                <span>{t("users-brands")}</span>
              <ul className="inline-list">
                <li>
                  <img src={logo_white} alt="Gimme - Order food and drinks via app" />
                </li>
                <li>
                  <img src={logo_white} alt="Gimme - Order food and drinks via app" />
                </li>
                <li>
                  <img src={logo_white} alt="Gimme - Order food and drinks via app" />
                </li>
                <li>
                  <img src={logo_white} alt="Gimme - Order food and drinks via app" />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <StickyScroll />
          <FeatureBoxList />
          <Gdpr />
          <Review />
          <Accordions />
          <FloatingButtons />
          <Footer />
        </div>
      </div>
    );
  }
}

export default withTranslation("page-forusers")(ForUsers);

//export default(ForUsers);

// WAS THERE
// <FeaturesIncluded />
// <GreyCTA />
// <div className="container-fluid intro-opener flat">
//            <div className="background-helper">
//              <div className="row">
//                <div className="col-sm-12 col-md-5 col-lg-5">
//                  <h2>{t("users-opener-headline")}</h2>
//                </div>
//                <div className="col-sm-12 col-md-6 col-lg-6">
//                  <p>{t("users-opener-description")}</p>
//                </div>
//              </div>
//            </div>
//          </div>
