import React from "react";
import { withTranslation } from "react-i18next";

//import logo from '../res/images/industry.png';

class BenefitBlocks extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid benefits-component">
        <div className="background-helper">
          <div className="row selling-wrapper">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h2>{t("benefits-headline")}</h2>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <p>{t("benefits-description")}</p>
              <ul className="button-group">
                <li>
                  <a href="https://app.joingimme.com/sign-up" className="primary-button">{t("benefits-start")}</a>
                </li>
                <li>
                  <span className="secondary-button">
                    {t("benefits-contact")}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="row blocks">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="customer">{t("benefits-headline1")}</h3>
                <p>{t("benefits-description1")}</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="loyalty">{t("benefits-headline2")}</h3>
                <p>{t("benefits-description2")}</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="takeaway">{t("benefits-headline3")}</h3>
                <p>{t("benefits-description3")}</p>
              </div>
            </div>
          </div>

          <div className="row blocks">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="delivery">{t("benefits-headline4")}</h3>
                <p>{t("benefits-description4")}</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="integrations">{t("benefits-headline5")}</h3>
                <p>{t("benefits-description5")}</p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div className="blocks-helper">
                <h3 className="consulting">{t("benefits-headline6")}</h3>
                <p>{t("benefits-description6")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-benefits")(BenefitBlocks);
