import React, { useState } from 'react';
import { withTranslation } from "react-i18next";
/* Modal */
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const FloatingButtons = () => {
    
    const [headerClassName, setHeaderClassName] = useState('');

    const handleScroll = (headerClassName) => {
        if (headerClassName !== 'menuscroll' && window.pageYOffset >= 768) {
            setHeaderClassName('menuscroll');
        } else if (headerClassName === 'menuscroll' && window.pageYOffset < 768) {
            setHeaderClassName('');
        }
    }

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    React.useEffect(() => {
        window.onscroll = () => handleScroll(headerClassName);
    }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

    return (
        <div className={`${headerClassName} floating`}>
      <ul>
          <li>
              <a href="https://app.joingimme.com/sign-up" className="primary-button">Get Started For Free</a>
          </li>
          <li>
              <a href="https://app.joingimme.com/sign-in" className="secondary-button">Login</a>
          </li>
          <li>
              <a href="#" onClick={onOpenModal}>Support Us</a>
          </li>
      </ul>

      <Modal open={open} onClose={onCloseModal} center>
      <iframe src="https://gimmeapp.gumroad.com/l/pvpdd" width="640" height="900" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>


          </Modal>
   
        </div>
    );
}

export default withTranslation("floating-buttons")(FloatingButtons);

// {t("talkSales")}
// {t("loginButton")}
// {t("tryForFree")}