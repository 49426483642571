import React from "react";
import { withTranslation } from "react-i18next";

import starFilled from "../res/images/reviews/ico_star_filled.svg";
//import starEmpty from '../res/images/reviews/ico_star_empty.svg';
import reviewAvatar from "../res/images/reviews/review_avatar.png";

class Review extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid review-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <span className="xl-typo">{t("review-headline")}</span>

              <ul className="review-stars no-style">
                <li>
                  <img
                    src={starFilled}
                    alt="Gimme - Order food and drinks via app"
                  />
                </li>
                <li>
                  <img
                    src={starFilled}
                    alt="Gimme - Order food and drinks via app"
                  />
                </li>
                <li>
                  <img
                    src={starFilled}
                    alt="Gimme - Order food and drinks via app"
                  />
                </li>
                <li>
                  <img
                    src={starFilled}
                    alt="Gimme - Order food and drinks via app"
                  />
                </li>
                <li>
                  <img
                    src={starFilled}
                    alt="Gimme - Order food and drinks via app"
                  />
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <p>{t("review-description")}</p>
              <div className="user-block vertical-align">
                <img
                  src={reviewAvatar}
                  alt="Gimme - Order food and drinks via app"
                />
                <span className="user-info">
                  <h4>Goran Ramljak</h4>
                  <p>{t("reviewer-status")}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-review")(Review);

//export default(Review);
