import React from "react";
// import { NavLink, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import heart from "../res/images/pricing/ico_heart.svg";
// import shop from "../res/images/pricing/ico_shopping.svg";
// import phone from "../res/images/pricing/ico_phone.svg";

// import FunFactsComponents from "../components/FunFactsComponents";
// import IntroOpener from "../components/IntroOpener";
// import WhiteLabelPromo from "../components/WhiteLabelPromo";
import Accordions from "../components/Accordions-second";
import Expert from "../components/Expert";
import Footer from "../components/Footer";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
// import ZigZagContent from "../components/ZigZagContent";
// import SecureSeparator from "../components/SecureSeparator";
import PricingComponent from "../components/PricingComponent";
import FloatingButtons from "../components/FloatingButtons";


// import adminScreenshot1 from "../res/images/for-business/admin-1.png";
// import adminScreenshot2 from "../res/images/for-business/admin-2.png";

//import WhiteLabel from '../components/WhiteLabel';

/* Pricing Variations */
// import ZigZagDefault from "../components/pricing/ZigZagDefault";
// import ZigZagLoyalty from "../components/pricing/ZigZagLoyalty";
// import ZigZagWhiteLabel from "../components/pricing/ZigZagWhiteLabel";

class Pricing extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="pricing-page">
        <div className="content-wrapper">
          <div className="container-fluid package-switcher">
            <div className="opener">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <span className="xxl-typo">
                    {t("pricing-headline1")}
                    <span> {t("pricing-headline2")} &#127881;</span>
                  </span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("pricing-description"),
                    }}
                  ></p>
                </div>
              </div>

            </div>
          </div>
          <PricingComponent />


          <Expert />
          <Accordions />
          <FloatingButtons />
          <Footer />
        </div>
      </div>
    );
  }
}

export default withTranslation("page-pricing")(Pricing);

//export default(Pricing);

/*
export default withTranslation('stats')(Stats);

              <img src={plastics} alt='bell' />
*/
