import React from "react";

const Loader = () => (
  <div className="lds-css">
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
