import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';

const app = (
  <Router>
    <Switch>
      <App />
    </Switch>
  </Router>
);
ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();