import React from "react";
import { withTranslation } from "react-i18next";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
//import logo from '../res/images/industry.png';

class Accordions extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="container-fluid accordion-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <h1>{t("faq-headline-fourth")}</h1>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <p>{t("faq-description-fourth")}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-1")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-1")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-2")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-2")}</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-3")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-3")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-4")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-4")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-5")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-5")}</p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <button className="indicator">
                        <span className="indi--top"></span>
                        <span className="indi--bot"></span>
                      </button>
                      {t("headline-fourth-6")}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{t("description-fourth-6")}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-accordion")(Accordions);
