import React from "react";
import { withTranslation } from "react-i18next";

class PricingComponent extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid intro-opener pricing-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h2>{t("pricing-features")}</h2>
              <ul className="fun-facts-vertical fun-facts">
                  <li>{t("no-fee")}</li>
                  <li>{t("free-to-start")}</li>
                  <li>{t("no-limit")}</li>
                  <li>{t("access-to-all-features")}</li>
                  <li>{t("community")} <a href="https://discord.gg/5tqUJM2q" target="_blank">Join Discord Server</a></li>
              </ul>
            </div>

            <div className="plan col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h2>{t("plan-name")}</h2>
            <p>{t("plan-explainer")}</p>
              <ul>
                  <li className="order">{t("order-ahead")}</li>
                  <li className="takeaway-option">{t("takeaway")}</li>
                  <li className="qr">{t("qr-codes")}</li>
                  <li className="loyalty-system">{t("loyalty")}</li>
                  <li className="multiple">{t("multiple-stores")}</li>
                  <li className="unlimited">{t("unlimited-menu")}</li>
                  <li className="custom">{t("custom-store")}</li>
                  <li className="free">{t("free-menu-setup")}<span className="new-badge">{t("new")}</span></li>
                  <li className="native">{t("native-app-request")}</li>
                  <li className="check">{t("many-more")} </li>
              </ul>
              <a href="https://app.joingimme.com/sign-up" className="secondary-button">{t("opener-contact")}</a>
            </div>
          </div>
          <div className="row pricing-benefits">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <span className="free center-align">
                    <h3>{t("trials-headline")}</h3>
                    <p>{t("trials-paragraph")}</p>
                </span>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <span className="satisfaction center-align">
                  <h3>{t("satisfaction-headline")}</h3>
                  <p>{t("satisfaction-paragraph")}</p>
              </span>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <span className="help center-align">
                  <h3>{t("help-paragraph")}</h3> <a href="">Contact us</a>
              </span>
          </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("pricing-component")(PricingComponent);

// export default(IntroOpener);
