import React from "react";
import { withTranslation } from "react-i18next";

class WhiteLabel extends React.Component {
  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    return (
      <div className="container-fluid white-label">
        <div className="background-helper">
          <div className="row vertical-align">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-md-offset-6 col-lg-offset-6">
              <h3>{t("white-label-headline")}</h3>
              <p>{t("white-label-description")}</p>
              <span className="primary-button">{t("contact-us")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-white-label")(WhiteLabel);

// export default(WhiteLabel);
