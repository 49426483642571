import React, { Suspense } from "react";
import { withTranslation } from "react-i18next";
import Loader from "../components/Loader";

//import logo from '../res/images/industry.png';

class Gdpr extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <Suspense fallback={<Loader />}>
        <div className="container-fluid gdpr-component">
          <div className="background-helper">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-md-offset-4 col-lg-offset-4">
                <span>{t("gdpr-subline")}</span>
                <h1>{t("gdpr-headline")}</h1>
                <p>{t("gdpr-description")}</p>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
}
export default withTranslation("component-gdpr")(Gdpr);
