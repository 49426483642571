import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';

/* All components */
import Industry from '../Industry';
import BenefitBlocks from '../BenefitBlocks';
// import StickyScroll from '../StickyScroll';
import Review from '../Review';
// import Prices from '../pricing/Prices';
import Accordions from '../Accordions-fourth';
// import Expert from '../Expert';
// import FeaturesIncluded from '../FeaturesIncluded';
// import Gdpr from '../Gdpr';
// import GreyCTA from '../GreyCTA';
import Footer from '../Footer';
// import Security from '../Security';
// import ZigZagContent from '../ZigZagContent';
import SecureSeparator from '../SecureSeparator';
// import WhiteLabel from '../WhiteLabel';
import FloatingButtons from "../FloatingButtons";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";

/* Modal */
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

/* Clients  */
import logo from '../../res/images/gimme_logo_purple.svg';

// import adminScreenshot1 from '../../res/images/for-business/admin-1.png';
// import adminScreenshot2 from '../../res/images/for-business/admin-2.png';

class ForBusiness extends React.Component {
  /* Modal */
  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { t } = this.props;
    //    const { t } = useTranslation('page');

    /* Modal */
    const { open } = this.state;
    /* Modal */

    return (
      <div className='business-wrapper'>
          <div className='page-opener'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8'>
                  <h1>{t('business-opener-headline')}</h1>
                  <p>{t('business-opener-description')}</p>
                  <ul className='button-group'>
                    <li>
                      <a href="https://app.joingimme.com/sign-up" className='primary-button'>
                        {t('business-start')}
                      </a>
                    </li>

            
                  </ul>
                  <ul className='fun-facts'>
                    <li>{t('business-fact1')}</li>
                    <li>{t('business-fact2')}</li>
                    <li>{t('business-fact3')}</li>
                    <li>{t('business-fact4')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='promo-space'>
              <span>{t('business-trust')}</span>
              <ul className='no-style'>
                <li>
                  <img src={logo} alt='Gimme - Order food and drinks via app' />
                </li>
                <li>
                  <img src={logo} alt='Gimme - Order food and drinks via app' />
                </li>
                <li>
                  <img src={logo} alt='Gimme - Order food and drinks via app' />
                </li>
                <li>
                  <img src={logo} alt='Gimme - Order food and drinks via app' />
                </li>
              </ul>
            </div>
          </div>

          <div className='content-wrapper'>
            <Modal open={open} onClose={this.onCloseModal} center>
              <h2>Simple centered modal</h2>
            </Modal>

            <Industry />
            <Review />
            <SecureSeparator />
            <BenefitBlocks />

            <Accordions />


            <FloatingButtons />
            <Footer />
          </div>
      </div>
    );
  }
}
export default withTranslation('page-backoffice')(ForBusiness);

/* 
            <WhiteLabel />

            <FeaturesIncluded />


                    <li>
                      <span
                        onClick={this.onOpenModal}
                        className='secondary-button video'
                      >
                        {t('business-demo')}
                      </span>
                    </li>
*/