import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";

function ZigZagContent({ zigZagContent }) {
  const { t } = useTranslation("component-zig-zag");
  return (
    <div className="container-fluid sticky-component">
      <div className="background-helper">
        {zigZagContent.map((item) => {
          return (
            <div key={item.headline} className="row sticky-content">
              {item.textFirst ? (
                <>
                  <Suspense fallback={<Loader />}>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky">
                      <h2>{t(`${item.headline}`)}</h2>
                      <p>{t(`${item.description}`)}</p>
                    </div>
                  </Suspense>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <img src={item.img} alt="ZigZag" />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <img src={item.img} alt="ZigZag" />
                  </div>

                  <Suspense fallback={<Loader />}>
                    <div
                      className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 sticky`}
                    >
                      <h2>{t(`${item.headline}`)}</h2>
                      <p>{t(`${item.description}`)}</p>
                    </div>
                  </Suspense>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ZigZagContent;
