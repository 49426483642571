import React from "react";
import { withTranslation } from "react-i18next";
// import android from "../res/images/for-users/badge_android.svg";
// import web from "../res/images/for-users/badge_web.svg";
// import ios from "../res/images/for-users/badge_ios.svg";

class HowItWorksOpener extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
       <div className="page-opener">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <h1>{t("how-it-works-headline")}</h1>
                <p>{t("how-it-works-description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("how-it-works-opener")(HowItWorksOpener);

// export default(FeaturesIncluded);
