import React from "react";
import { useTranslation } from "react-i18next";

function LangSwitcher() {
  const { t, i18n } = useTranslation("navbar");

  return (
    <div className="language-switcher">
      <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
        <option>{t("Select Language")}</option>
        <option value="en" label="🇺🇸 English" symbol="🇺🇸">
          {" "}
          English
        </option>
        <option value="hr" label="🇭🇷 Hrvatski" symbol="🇭🇷">
          {" "}
          Hrvatski
        </option>
        <option value="de" label="🇩🇪 Deutsch" symbol="🇩🇪">
          {" "}
          Deutsch
        </option>
      </select>
    </div>
  );
}

export default LangSwitcher;
