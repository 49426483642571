import React from "react";
import { withTranslation } from "react-i18next";

class Prices extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid expert-component">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2">
              <h2>{t("expert-headline")}</h2>
              <p>{t("expert-description")}</p>
              <a href="mailto:goran.ramljak@zirelco.ch" className="secondary-button">{t("expert-contact")}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("component-expert")(Prices);
