import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from "react-i18next";
import barShop from "../res/images/bar-store.webp";
import flowerShop from "../res/images/flower-store.webp";
import restaurantShop from "../res/images/restaurant-store.webp";

class WinWinBlock extends React.Component {
  render() {
    const { t } = this.props;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        lazyLoad: "progressive",
        slidesToShow: 3,
      };
    return (
      <div className="container-fluid">
            <div className="win-win-component">
            <div className="background-helper minimised">

            <div className="row headline">
                <h2>{t("win-win")}</h2>
            </div>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <div className="blocks-helper">
                    <h4 className="save-money">{t("saved")}</h4>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <div className="blocks-helper">
                    <h4 className="tips">{t("more-tips")}</h4>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <div className="blocks-helper">
                    <h4 className="basket">{t("average-basket")}</h4>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <div className="blocks-helper">
                    <h4 className="adaptation">{t("adoption-rate")}</h4>
                  </div>
                </div>
              </div>
              <div className="row opener center-align">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2>{t("opener-headline")}</h2>
                    <p>{t("opener-paragraph")}</p>
                  </div>
              </div>
              <Slider {...settings}>
          <a href="#" className="store-example blue">
            <img src={restaurantShop} loading="lazy" alt="" className="resize menu-thumbnail"></img>
            <span className="example-content">
            <h3>{t("name-restaurant")}</h3>
            <p>{t("industry-hospitality")}</p>
            <span>{t("visit-store")}</span>
            </span>
          </a>
          

          <a href="#" className="store-example yellow">
            <img src={barShop} loading="lazy" alt="" className="resize menu-thumbnail"></img>
            <span className="example-content">
            <h3>{t("name-coffe")}</h3>
            <p>{t("industry-hospitality")}</p>
            <span>{t("visit-store")}</span>
            </span>
          </a>
          
          <a href="#" className="store-example red">
            <img src={flowerShop} loading="lazy" alt="" className="resize menu-thumbnail"></img>
            <span className="example-content">
            <h3>{t("name-flower")}</h3>
            <p>{t("industry-flower-shop")}</p>
            <span>{t("visit-store")}</span>
            </span>
          </a>

 
        </Slider>
         
            </div>
          </div>
      </div>
    );
  }
}
export default withTranslation("win-win-block")(WinWinBlock);