import React from "react";
//import { useTranslation } from 'react-i18next';
import { withTranslation } from "react-i18next";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import android from "../res/images/for-users/badge_android.svg";
//import web from "../res/images/for-users/badge_web.svg";
//import ios from "../res/images/for-users/badge_ios.svg";

class MarketingSlider extends React.Component {
  render() {
    const { t } = this.props;

    const slidesData = [
      {
        id: 1,
        img: "https://arzt-zilic.com/gimme/phone_scan.svg",
        title: "Scan or select",
        label: "with any smartphone and no app download. Customers can scan the QR code on their table to get instant access to the digital menu. ",
      },
      {
        id: 2,
        img: "https://arzt-zilic.com/gimme/phone_order.svg",
        title: "Order",
        label: "Just order what you want by selecting the table number OR scan the QR code.",
      },
      {
        id: 3,
        img: "https://arzt-zilic.com/gimme/phone_pay.svg",
        title: "Pay",
        label: "rather than waiting for someone to bring the bill, customers can pay quickly and securely through Gimme. Cash, paypal or credit cards!",
      },
      {
        id: 4,
        img: "https://arzt-zilic.com/gimme/phone_cards.svg",
        title: "Store loyalty cards",
        label: "Besides having option to buy, your customers have option to store their loyalty cards as well. From now on your customers have all their loyalty cards stored within one place",
      },
    ];

    const settings = {
      customPaging: function (i) {
        return (
          <span>
            <>
              <span>{slidesData[i].title}</span>
              <span>{slidesData[i].label}</span>
            </>
          </span>
        );
      },
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      arrows: false,
      lazyLoad: "progressive",
    };

    return (
      <div className="container-fluid marketing-slider">
        <div className="background-helper">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-align">
              <div className="text-helper">
                <h2>{t("carouselHeadline")}</h2>
                <p>{t("carouselDescription")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Slider {...settings}>
                {slidesData.map((slide) => (
                  <img
                    key={slide.id}
                    className="slick-slide-image"
                    src={slide.img}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default(MarketingSlider);
export default withTranslation("component-slider")(MarketingSlider);
