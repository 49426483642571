import React from "react";
import { withTranslation } from "react-i18next";

class FeatureBoxList extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid">
        <div className="background-helper features-box-list">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className="helper">
              <h3 className="center-align qr-code">
                {t("no-download-headline")}
              </h3>
              <p>{t("no-download-paragraph")}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="helper">
              <h3 className="center-align pen">
                {t("customization-headline")}
              </h3>
              <p> {t("customization-paragraph")}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="helper">
              <h3 className="center-align analytics">
              {t("analytics-headline")}
              </h3>
              <p>{t("analytics-paragraph")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="helper">
              <h3 className="center-align translate">
              {t("multilanguage-headline")}
              </h3>
              <p>{t("multilanguage-paragraph")}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="helper">
              <h3 className="center-align cloud">
                {t("up-to-date-headline")}
              </h3>
              <p>{t("up-to-date-paragraph")}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div className="helper">
              <h3 className="center-align shield">
                {t("contactless-headline")}
              </h3>
              <p>{t("contactless-paragraph")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("feature-box-list")(FeatureBoxList);

// export default(FeaturesIncluded);
