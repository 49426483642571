import React, { useState, useEffect, Suspense } from 'react';
import { Route } from 'react-router-dom';
import NavBar from './NavBar';
import Why from './pages/Why';
import ForBusiness from './pages/ForBusiness';
import ForUsers from './pages/ForUsers';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import onClickOutside from 'react-onclickoutside';
import AboutUsPopup from './components/AboutUsPopup';

require('./res/scss/main.scss');

function App() {
  const [open, setOpen] = useState(false);
  const togglePopup = () => setOpen(!open);
  App.handleClickOutside = () => setOpen(false);

  useEffect(() => {}, []);

  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <NavBar togglePopup={togglePopup} />
        <Route path='/' exact component={Why} />
        <Route path='/business' component={ForBusiness} />
        <Route path='/users' component={ForUsers} />
        <Route path='/pricing' component={Pricing} />
        <Route path='/blog' component={Blog} />
      </Suspense>
      <Suspense fallback={<p>Loading...</p>}>
        {open && <AboutUsPopup />}
      </Suspense>
    </>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => App.handleClickOutside,
};

export default onClickOutside(App, clickOutsideConfig);
